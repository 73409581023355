import React, { useState } from 'react';
import {  Link } from 'react-router-dom'; // Import HashRouter and Link from react-router-dom
import './Navbar.css';
import { MdWifiCalling3 } from "react-icons/md";
import { IoMdMail } from "react-icons/io";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    
      <header>
        <nav className="navbar">
          <div className="logo">
            <Link to="/"> {/* Use Link component instead of anchor tags */}
              Nathna<span className="orange-text">el</span>
            </Link>
          </div>
          <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
            <li><Link to="/about">About</Link></li> {/* Hash routes using Link */}
            <li><Link to="/projects">Products</Link></li>
            <li><Link to="/Portfolio">Portfolio</Link></li>
            <MdWifiCalling3 color='aliceblue' size={20} />
            <IoMdMail color='aliceblue' size={20} />
          </ul>
          <Link to="#contact" className="cta-button">Hire Me</Link>
          <div className="hamburger" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>
    
  );
};

export default Navbar;
