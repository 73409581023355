import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { RiAlertFill } from "react-icons/ri";
import Oader from './components/Loaders'; // Fixed typo
import Products from './components/Products';
import About from './components/About';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Use only one Router
import Portfolio from './components/Portfolio';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  // Show the popup after 12 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 12000); // 12 seconds

    // Cleanup timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // Simulating loading effect for 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after 3 seconds
    }, 3000); // 3000 ms = 3 seconds

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Display loader while loading is true
  if (loading) {
    return <Oader style={{ marginTop: '50%', marginLeft: '40%' }} />; 
  }

  return (
    <div className='App'>
      <Router> {/* Only one Router */}
        <Navbar />

        {/* Routes in React Router v6 */}
        <Routes> 
        <Route path="/" element={<About />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Products />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          {/* Add other routes as necessary */}
        </Routes>

        <Footer />

        {/* Popup Modal */}
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <RiAlertFill color='orange' size={50} />
              <h2>Website Under Construction</h2>
              <p style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
                This website is currently under construction. Check back later for updates!
                You can use this email if you have urgent inquiries: <span style={{ color: 'darkorange' }}>nathnaeldem@yahoo.com</span>.
              </p>
              <button onClick={closePopup} className="close-button">Show me anyway</button>
            </div>
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;
