import React from 'react';
import Doctor from './Doctor.png'
import Lawyer from './lawyer.png'
import Psychatrist from './psycha.png.png'
import Webpage from './webpage.png'
import Ecomm from './Ecommerce.png'
import Seo from './Seo.png'

const Products = () => {
  return (
    <div style={{  color: 'aliceblue', padding: '50px 20px'
       }}>
      {/* Top Section */}
      <section style={{  textAlign: 'center', padding: '30px 0' }}>
        <h1 style={{ fontSize: '4.3rem', marginBottom: '20px' }}>Explore Our Digital Solutions</h1>
        <p style={{ padding:'0 70px 0px 20px',fontSize: '1.2rem', marginBottom: '30px' }}>
          Delivering Innovative Products for Your Business Needs
        </p>
        <div>
          <button style={buttonStyleBlue}>Browse Products</button>
          <button style={buttonStylePink}>Contact Us</button>
        </div>
      </section>

      {/* Why You Need a Website */}
      <section style={{marginTop:'70px' , padding: '50px 0' }}>
        <h2 style={{ fontSize: '2.5rem', textAlign: 'center', marginBottom: '50px' }}>Why You Need a Website</h2>

        {/* Section 1 - Lawyers */}
        <div style={sectionContainerStyle}>
          <div style={textSectionStyle}>
            <h3 style={{ fontSize: '2rem',color:'darkorange', marginBottom: '20px',textAlign:'center' }}>Are you a Lawyer?<br/><span style={{fontSize: '1.5rem',color:'rgb(208, 212, 215)'}}>Establish Your Online  Authority</span>  </h3>
            <p style={{ padding:'0 70px 0px 20px',fontSize: '1.2rem', marginBottom: '20px' }}>
              As a lawyer, having an online presence allows you to build trust and authority with potential clients. A
              website helps you showcase your expertise, display client testimonials, and provide easy access to your
              services.
            </p>
            <ul style={{ fontSize: '1.1rem',paddingLeft:'20px',marginLeft:'17%' }}>
              <li>Showcase your legal services</li>
              <li>Offer online consultations</li>
              <li>Build credibility with client reviews</li>
            </ul>
           
          </div>
          <div style={{display:'grid'}}>
            <img src={Lawyer} alt="Lawyer Website" style={imageStyle} />
            <button style={buttonStyleBlue2}>Request a Quote</button>
          </div>
        </div>

        {/* Section 2 - Doctors */}
        <div style={sectionContainerStyle}>
          <div style={{display:'grid'}}>
            <img src={Doctor} alt="Doctor Website" style={imageStyle} />
            <button style={buttonStyleBlue2}>Request a Quote</button>
          </div>
          <div className='Doct' style={textSectionStyle}>
            <h3 style={{ fontSize: '2rem',color:'darkorange', marginBottom: '20px' }}>Are you a Doctor?<span style={{fontSize: '1.5rem',color:'rgb(208, 212, 215)'}}>&nbsp; Build Trust with Patients</span></h3>
            <p style={{ padding:'0 70px 0px 20px',fontSize: '1.2rem', marginBottom: '20px' }}>
              For medical professionals, a website is an essential tool for building trust with patients. It allows you
              to share your qualifications, medical services, and success stories, making it easier for patients to
              reach you and book appointments.
            </p>
            <ul style={{ fontSize: '1.1rem',paddingLeft:'20px',listStyle:'none' }}>
              <li>Highlight your qualifications and expertise</li>
              <li>Provide 24/7 access to appointment scheduling</li>
              <li>Educate patients with medical blogs or FAQs</li>
            </ul>
           
          </div>
        </div>

        {/* Section 3 - Psychiatrists */}
        <div style={sectionContainerStyle}>
          <div style={textSectionStyle}>
            <h3 style={{ fontSize: '2rem',color:'darkorange', marginBottom: '20px' }}>Are you a Psychiatrist? <span style={{fontSize: '1.5rem',color:'rgb(208, 212, 215)'}}>&nbsp; Reach More Patients</span></h3>
            <p style={{ padding:'0 70px 0px 20px',fontSize: '1.2rem', marginBottom: '20px' }}>
              Psychiatrists can benefit from a website by reaching more patients and providing a safe space for mental
              health information. A well-designed website can offer virtual consultations and help patients access
              important resources.
            </p>
            <ul style={{ fontSize: '1.1rem',paddingLeft:'20px',listStyle:'none' }}>
              <li>Offer virtual therapy sessions</li>
              <li>Share mental health resources</li>
              <li>Create a secure and confidential space for clients</li>
            </ul>
            
          </div>
          <div style={{display:'grid'}}>
            <img src={Psychatrist} alt="Psychiatrist Website" style={imageStyle} />
            <button style={buttonStyleBlue2}>Request a Quote</button>
          </div>
        </div>
      </section>

      {/* Products Showcase */}
      <div Id='newComp'>
      <section style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '30px', padding: '0 50px' }}>
        {/* Product Card 1 */}
        <ProductCard
          imgSrc={Webpage}
          name="Custom Website Development"
          description="Tailor-made websites designed to meet your business goals."
          price="Starting at $499"
        />
        {/* Product Card 2 */}
        <ProductCard
          imgSrc={Ecomm}
          name="E-commerce Solutions"
          description="Boost your business with a fully-functional online store."
          price="Starting at $699"
        />
        {/* Product Card 3 */}
        <ProductCard
          imgSrc={Seo}
          name="SEO & Digital Marketing"
          description="Enhance your online presence with top-notch SEO strategies."
          price="Starting at $399"
        />
      </section>

      {/* Client Testimonials */}
      <section style={{ textAlign: 'center', padding: '50px 0' }}>
        <h2 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>What Our Clients Say</h2>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Testimonial
            text="Their services are top-notch! My business website looks amazing and functions perfectly."
            client="aregahegn"
            imgSrc="https://via.placeholder.com/100"
          />
          <Testimonial
            text="Quick delivery and exceptional communication. Highly recommend!"
            client="william"
            imgSrc="https://via.placeholder.com/100"
          />
          <Testimonial
            text="Fantastic results! My e-commerce store has seen a huge boost in sales."
            client="Robert Johnson"
            imgSrc="https://via.placeholder.com/100"
          />
        </div>
      </section>
      </div>
    </div>
  );
};

// Button Styles
const buttonStyleBlue = {
  backgroundColor: '#007bff',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '30px',
  margin: '0 10px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '1rem'
};

const buttonStyleBlue2 = {
  backgroundColor: 'darkorange',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '30px',
  margin: '0 10px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '1.5rem',
  marginTop:'4%'
};

const buttonStylePink = {
  backgroundColor: '#ff007b',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '30px',
  margin: '0 10px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '1rem'
};

// Product Card Component
const ProductCard = ({ imgSrc, name, description, price }) => {
  return (
    <div style={productCardStyle}>
      <img src={imgSrc} alt={name} style={{ width: '100%', borderRadius: '15px' }} />
      <h3 style={{ fontSize: '1.5rem', margin: '20px 0 10px' }}>{name}</h3>
      <p style={{ marginBottom: '20px' }}>{description}</p>
      <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>{price}</p>
      <button style={buttonStylePink}>Order Now</button>
    </div>
  );
};

// Testimonial Component
const Testimonial = ({ text, client, imgSrc }) => {
  return (
    <div style={testimonialStyle}>
      <img src={imgSrc} alt={client} style={{ width: '80px', borderRadius: '50%', marginBottom: '10px' }} />
      <p style={{ fontStyle: 'italic', marginBottom: '10px' }}>"{text}"</p>
      <p style={{ fontWeight: 'bold' }}>{client}</p>
    </div>
  );
};

// Styles
const productCardStyle = {
  backgroundColor: '#0e3d42',
  borderRadius: '20px',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease',
  cursor: 'pointer'
};

const testimonialStyle = {
  backgroundColor: '#0e3d42',
  borderRadius: '20px',
  padding: '20px',
  textAlign: 'center',
  width: '300px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
};

const sectionContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '50px',
  padding: '0 50px',
  textAlign:'left'
};

const textSectionStyle = {
    flex: 1,
    paddingRight: '30px',
  };
  
  const imageStyle = {
    width: '400px',
    height: 'auto',
    borderRadius: '15px',
  };
  
  export default Products;
  