import './loader.css'


const Oader = () =>{

    
    return(
        <div style={{marginLeft:'50%'}}>
<h1 style={{marginBottom:'50%'}}> </h1>
<div   className="loader"></div>
</div>
    );
};
export default Oader;