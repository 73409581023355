import React, { Component } from 'react';
import '../App.css';
import me from './nathi.png';
import { SiPhp, SiSolana, SiTypescript } from 'react-icons/si';
import { FaCss3, FaHtml5, FaJs, FaReact } from 'react-icons/fa';
import { RiNextjsFill, RiTailwindCssFill } from 'react-icons/ri';

export class WhoAmI extends Component {
  render() {
    return (
      <div style={{ height: '90vh', position: 'relative', overflow: 'hidden' }}>
        {/* Slanted background border covering the whole page */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%', // Cover the entire height
            background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(4,35,51,1) 34%, rgba(22,142,165,1) 95%)', // Combined background
            clipPath: 'polygon(0 34%, 100% 20%, 100% 100%, 0 100%)', // Create slant effect
            zIndex: 0, // Send it to the back
          }}
        ></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: '40px', // Allows wrapping for smaller screens
            position: 'relative', // Position relative for content layering
            zIndex: 1, // Bring content in front of the slant
          }}
        >
          <h2 style={{ color: 'aliceblue', flexBasis: '100%', marginBottom: '20px' }}>
            My qualifications
          </h2>

          {/* Left Column: List + Icons */}
          <div style={{ fontWeight:'500',display: 'flex', flexDirection: 'column', width: '40%', marginRight: '20px', marginLeft: '60px' }}>
            <div>
              <h2 style={{ color: 'orange', marginBottom: '10px' }}>2023 Computer Science graduate</h2>
              <ul style={{ marginLeft:'6%',color: 'rgba(226, 135, 17, 0.5)', textAlign: 'left', listStyleType: 'circle' }}>
                <li>More than 3 years of coding experience</li>
                <li>Fast learner</li>
                <li>Specializes in frontend development</li>
                <li>Proficient in React, Next.js, and Tailwind CSS</li>
                <li>Experience with Solana blockchain development</li>
              </ul>
            </div>

            {/* Icons in a linear layout */}
            <div
              className='icon-line'
              style={{
                display: 'flex',
                justifyContent: 'center', // Center the icons
                margin: '70px auto 0 auto',
              }}
            >
              <div style={iconStyle('cyan')}>
                <SiSolana />
              </div>
              <div style={iconStyle('yellow')}>
                <FaJs />
              </div>
              <div style={iconStyle('#3984d4')}>
                <SiTypescript />
              </div>
              <div style={iconStyle('cyan')}>
                <FaReact />
              </div>
              <div style={iconStyle('#e34c26')}>
                <FaHtml5 />
              </div>
              <div style={iconStyle('#264de4')}>
                <FaCss3 />
              </div>
              <div style={iconStyle('aliceblue')}>
                <RiNextjsFill />
              </div>
              <div style={iconStyle('cyan')}>
                <RiTailwindCssFill />
              </div>
              <div style={iconStyle('#777BB3')}>
                <SiPhp />
              </div>
            </div>
          </div>

          {/* Right Column: Image */}
          <img
            src={me}
            alt='me'
            style={{
              width: '50%', // Reduced width for responsiveness
              paddingLeft: '9%',
            }}
          />
        </div>
      </div>
    );
  }
}

// Helper function for styling icons
const iconStyle = (color = '#000') => ({
  fontSize: '30px',
  color,
  margin: '10px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', // Add drop shadow for better visibility
  
});

export default WhoAmI;
