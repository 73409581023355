import React from 'react';
import './ClientList.css';

const ClientList = () => {
  const clients = [
    { id: 1, service: 'Best Service', rating: 5 },
    { id: 2, service: 'Swift Delivery', rating: 5 },
    { id: 3, service: 'Excellent Communication', rating: 5 },
    
  ];

  return (
    <div className="container">
      <h1 className="heading">Join Our Client List</h1>
      <p className="subheading">Are you prepared to join us on your remarkable digital presence adventure?</p>
      
      <div className="client-cards">
        {clients.map(client => (
          <div key={client.id} className="card">
            <div className="image-placeholder"></div>
            <p>{client.service}</p>
            <div className="stars">
              {'★'.repeat(client.rating)}
            </div>
          </div>
        ))}
      </div>

      <button className="button">Contact Us</button>
    </div>
  );
};

export default ClientList;
