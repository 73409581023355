import React, { Component } from 'react'
import WhoAmI from './WhoAmI'
import Order from './Order'
import ClientList from './ClientList'
import { motion } from 'framer-motion'

export class About extends Component {
  render() {
    return (
      <div style={{marginTop:'15%'}}>
        <motion.div 
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 2 }}
          className="content"
        >
        <h1 style={{ marginBottom: '1.5%' }}>Hi, I'm <span style={{color:'darkorange'}}>Nathnael Demeke</span></h1>
        <h2 style={{  marginBottom: '0.5%' }}>
          Passionate Frontend Developer
        </h2>
      </motion.div>
      <motion.p initial={{ y: 50 }} animate={{ y: 0 }} transition={{ duration: 1 }}>
        <p style={{ margin: '1% 15% 1% 15%', textShadow:'2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
          With a keen eye for design and a deep understanding of modern web technologies, 
          I specialize in creating visually stunning and highly functional user interfaces. 
          My goal is to bring ideas to life on the web, ensuring a seamless and engaging 
          experience for every user.
        </p>
      </motion.p>
      <div style={{ margin: '40px', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <button className='ct-button'>Let's talk</button>
        <button className='ct-button2'>Contact me</button>
      </div>
      <Order />
      <ClientList />
      <WhoAmI />
      </div>
    )
  }
}

export default About
