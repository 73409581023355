import React from 'react';
import { FaLinkedin,FaGithub ,FaPhoneAlt, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";


const Footer = () => {
  const footerStyle = {
    backgroundColor: '#020024',
    color: '#f5f5f5',
    padding: '40px 20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  };

  const containerStyle = {
    maxWidth: '1200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  };

  const sectionStyle = {
    marginBottom: '20px',
    flexBasis: 'calc(33% - 20px)',
    minWidth: '245px',
    textAlign: 'left',
  };

  const titleStyle = {
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: '#ffffff',
    
  };

  const linkStyle = {
    color: '#f5f5f5',
    textDecoration: 'none',
    marginBottom: '8px',
    display: 'block',
    fontSize: '1rem'
  };

  const socialIconsStyle = {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '20px',
  };

  const iconStyle = {
    margin: '0 10px',
    fontSize: '1.5rem',
    color: '#f5f5f5',
    cursor: 'pointer',
  };

  const copyrightStyle = {
    marginTop: '20px',
    fontSize: '0.8rem',
    color: '#aaa'
  };

  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        {/* Quick Links Section */}
        <div style={sectionStyle}>
          <h3 style={titleStyle}>Quick Links</h3>
          <a href="/home" style={linkStyle}>Home</a>
          <a href="/about" style={linkStyle}>About Me</a>
          <a href="/projects" style={linkStyle}>Projects</a>
          <a href="/contact" style={linkStyle}>Contact</a>
        </div>

        {/* Contact Section */}
        <div style={sectionStyle}>
          <h3 style={titleStyle}>Get in Touch</h3>
          <p style={{ marginBottom: '8px', color: '#f5f5f5' }}><IoIosMail /> nathnaeldem@yahoo.com</p>
          <p style={{ marginBottom: '8px', color: '#f5f5f5' }}><FaPhoneAlt /> +251 777 520229</p>
          <p style={{ color: '#f5f5f5' }}> <FaLocationDot /> Addis Ababa, Ethiopia</p>
        </div>

        {/* Social Media Section */}
        <div style={sectionStyle}>
          <h3 style={titleStyle}>&nbsp;&nbsp;Follow Me</h3>
          <div style={socialIconsStyle}>
            <a href="https://github.com/nathnaeldem" style={iconStyle}>
              <i className="fab fa-github"></i> <FaGithub  />
            </a>
            <a href="https://www.linkedin.com/in/nathnael-demeke-lambebo-b29293108/" style={iconStyle}>
              <i className="fab fa-linkedin"></i><FaLinkedin  />
            </a>
            <a href="https://www.linkedin.com/in/nathnael-demeke-lambebo-b29293108/" style={iconStyle}>
              <i className="fab fa-twitter"></i> <FaXTwitter style={{color:'white'}} />
            </a>
            <a href="https://www.instagram.com/nathi_demeke/" style={iconStyle}>
              <i className="fab fa-twitter"></i> <FaInstagram style={{color:'white'}} />
            </a>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div style={copyrightStyle}>
        <p>&copy; {new Date().getFullYear()} Nathnael Demeke. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
