import React from 'react';
import './Portfolio.css'; // Assuming you will have the styles in a CSS file
import client from './clients.png'
import Welcome from './welcome.png'

const Portfolio = () => {
  const projects = [
    {
      title: 'Law Firm Website',
      description: 'A custom website designed for a law firm with a clean and professional layout.',
      techStack: ['HTML', 'CSS', 'JavaScript', 'WordPress'],
      image: '/images/law-firm.png', // Add your image paths
      link: '#',
    },
    {
      title: 'Medical Practice Website',
      description: 'Built a website for a doctor to help them reach their patients online.',
      techStack: ['HTML', 'CSS', 'React', 'Node.js'],
      image: '/images/medical-practice.png',
      link: '#',
    },
    {
      title: 'E-commerce Store',
      description: 'Developed a fully functional e-commerce store with custom product management.',
      techStack: ['React', 'Node.js', 'MongoDB'],
      image: '/images/ecommerce.png',
      link: '#',
    },
  ];

  return (
    <div className="portfolio-page">
      
      <header style={{textAlign:'left'}} className="portfolio-header">
      
        <div style={{padding:'0 7% 0 5%'}}>
      
          <h1><span style={{color:'orange',fontSize:'4rem'}}>My portfolio</span><br />Showcasing My Projects</h1>
        <p>Explore the projects that I’ve worked on, from custom website designs to complete digital solutions.</p>
     </div>
     <img style={{maxWidth:'40%',marginTop:'8%'}} src={Welcome} alt='here u go' />
         </header>

      <div className="portfolio-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <h2 className="project-title">{project.title}</h2>
            <p className="project-description">{project.description}</p>
            <div className="tech-stack">
              {project.techStack.map((tech, idx) => (
                <span key={idx} className="tech-item">{tech}</span>
              ))}
            </div>
            <a href={project.link} className="view-project-btn">View Project</a>
          </div>
        ))}
      </div>
     <div>
      <section style={{ textAlign: 'center', padding: '50px 0' }}>
        <h2 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>What Our Clients Say</h2>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Testimonial
            text="Their services are top-notch! My business website looks amazing and functions perfectly."
            client="aregahegn"
            imgSrc="https://via.placeholder.com/100"
          />
          <Testimonial
            text="Quick delivery and exceptional communication. Highly recommend!"
            client="william"
            imgSrc="https://via.placeholder.com/100"
          />
          <Testimonial
            text="Fantastic results! My e-commerce store has seen a huge boost in sales."
            client="Robert Johnson"
            imgSrc="https://via.placeholder.com/100"
          />
        </div>
      </section>
      </div>
    
      <div className='Bigcard' style={{display:'flex',textAlign:'left',padding:'2.5rem',maxWidth:'90vw',borderRadius:'3rem',marginLeft:'3%'}} >
        <div style={{width:'50%'}} className="cta-section">
        <h3 style={{color:'darkorange'}}>Are you prepared to join us on your remarkable digital presence adventure?</h3>
        <h2>Join Our Client List</h2>
        <p>Behind every beautiful website, there is a creative company like ours. Let’s take you through a captivating digital presence adventure as you partner with us. We use cutting-edge tactics to uncover hidden potential, and amplify your online visibility to make a lasting impression in the digital realm. Join us on this thrilling expedition to maximize your online influence and achieve unprecedented success.</p>
        <button href="#contact" style={{margin:'2% 0 0 6%',fontSize:'1rem',borderRadius:'2rem'}} className="cta-btn">Contact us</button>
        </div>
        <div style={{width:'50%'}}><img style={{width:'100%',padding:'2rem'}} src={client} alt='img' /></div>
      </div>
    </div>
  );
};

const Testimonial = ({ text, client, imgSrc }) => {
    return (
      <div style={testimonialStyle}>
        <img src={imgSrc} alt={client} style={{ width: '80px', borderRadius: '50%', marginBottom: '10px' }} />
        <p style={{ fontStyle: 'italic', marginBottom: '10px' }}>"{text}"</p>
        <p style={{ fontWeight: 'bold' }}>{client}</p>
      </div>
    );
  };
  const testimonialStyle = {
    backgroundColor: '#0e3d42',
    borderRadius: '20px',
    padding: '20px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
  };
  

export default Portfolio;


