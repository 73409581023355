import '../App.css';
import web from './webpage.jpeg';
import { motion } from 'framer-motion';
const Order = () => {
    return (
        <div  style={{ marginTop: '15%' }}>
           
            <div className='ord' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                <img 
                    src={web} 
                    alt='webpage' 
                    style={{ 
                        width: '50%', 
                        marginLeft: '2%', 
                        borderRadius: '18px', 
                        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.4)' 
                    }} 
                    className="order-image" // added class for mobile styles
                />
                <div style={{ marginLeft: '2%', display: 'block', textAlign: 'center' }}>
                <motion.div 
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ duration: 3 }}
          className="content"
        >
             <h3>
                Are you a Doctor , a Lawyer or a Psychatrist?
             </h3>
                    <h2 style={{ 
                        width: '100%', 
                        color: 'aliceblue', 
                        textAlign: 'left', 
                        whiteSpace: 'nowrap',
                        textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',  
                    }}
                    className='ordh2'
                    >
                        Do you need a website?
                    </h2>
                    </motion.div>
                    <motion.p initial={{ y: 50 }} animate={{ y: 0 }} transition={{ duration: 1 }}>
                    <p style={{
                        color: 'rgb(186, 190, 194)',
                        marginTop: '2%',
                        marginBottom: '4%',
                        textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)'
                    }}>
                        That will be completed within a deadline.
                    </p>

                    <button className="cto-button">Order now</button>
                    </motion.p>
                </div>
            </div>
        </div>
    );
};

export default Order;
